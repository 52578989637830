const purposes = [
    {
        id: '0',
        task: 'Провести обстеження технологічного процесу споживання штучного холоду для видачі попередніх технічних рішень з реконструкції. Переговори з технічнирм керівництвом.',
        short: 'Обстеження об\'єкта проєктування',
        done: 'Проведено обстеження технологічного процесу споживання штучного холоду для видачі попередніх технічних рішень з реконструкції. Переговори з технічнирм керівництвом.'
    },
    {
        id: '1',
        task: 'Виконання інжинірингових, проєктних, обмірних робіт з  технічного переоснащення АХУ холодильника',
        short: 'Технічне переоснащення АХУ холодильника',
        done: 'Виконано інжинірингові, проєктні, обмірні роботи з  технічного переоснащення АХУ холодильника'
    },
    {
        id: '2',
        task: 'Акт звірки та інвентарізафція бухг. документації',
        short: 'Провести бухгалтерську звірку ',
        done: 'Проведено бухгалтерську звірку '
    }



]

export default purposes