const employes = [
    {
        id: 'kozachenko',
        name: 'Козаченко Ілля Сергійович',
        nameTo: 'Козаченку Іллі Сергійовичу',
        nameWho: 'Козаченко Іллю Сергійовича',
        nameAbbrev: 'Козаченко І.С.',
        nameAbbrevWho: 'Козаченко І.С.',
        position: 'Провідний інженер'
    },
    {
        id: 'zhelibat',
        name: 'Желіба Тетяна Олександрівна',
        nameTo: 'Желібі Тетяні Олександрівній',
        nameWho: 'Желібу Тетяну Олександрівну',
        nameAbbrev: 'Желіба Т.О.',
        nameAbbrevWho: 'Желібу Т.О.',
        position: 'Начальник комерційного відділення'
    },
    {
        id: 'voytkod',
        name: 'Войтко Дмитро Андрійович',
        nameTo: 'Войтко Дмитру Андрійовичу',
        nameWho: 'Войтко Дмитра Андрійовича',
        nameAbbrev: 'Войтко Д.А.',
        nameAbbrevWho: 'Войтко Д.А.',
        position: 'Начальник технічного відділення'
    },
    {
        id: 'rimashevskiy',
        name: 'Рімашевський Юрій Сергійович',
        nameTo: 'Рімашевському Юрію Сергійовичу',
        nameWho: 'Рімашевського Юрія Сергійовича',
        nameAbbrev: 'Рімашевський Ю.С.',
        nameAbbrevWho: 'Рімашевського Ю.С.',
        position: 'Керівник групи ВКП'
    },
    {
        id: 'slivinska',
        name: 'Сливинська Марина Володимирівна',
        nameTo: 'Сливинській Марині Володимирівні',
        nameWho: 'Сливинську Марину Володимирівну',
        nameAbbrev: 'Сливинська М.В.',
        nameAbbrevWho: 'Сливинську М.В.',
        position: 'Начальник відділу комплексного проектування'
    },
    {
        id: 'zhelibay',
        name: 'Желіба Юрій Олександрович',
        nameTo: 'Желібі Юрію Олександровичу',
        nameWho: 'Желібу Юрія Олександровича',
        nameAbbrev: 'Желіба Ю.О.',
        nameAbbrevWho: 'Желібу Ю.О.',
        position: 'Директор'
    },
    {
        id: 'kostukova',
        name: 'Костюкова Ольга Сергіївна',
        nameTo: 'Костюковій Ользі Сергіївній',
        nameWho: 'Костюкову Ольгу Сергіївну',
        nameAbbrev: 'Костюкова О.С.',
        nameAbbrevWho: 'Костюкову О.С.',
        position: 'Головний бухгалтер'
    },
    {
        id: 'shumskiy',
        name: 'Шумський Олексій Аркадійович',
        nameTo: 'Шумському Олексію Аркадійовичу',
        nameWho: 'Шумського Олексія Аркадійовича',
        nameAbbrev: 'Шумський О.А.',
        nameAbbrevWho: 'Шумського О.А.',
        position: 'Інженер ІІ категорії'
    },
    {
        id: 'vovnenko',
        name: 'Вовненко Вадим Сергійович',
        nameTo: 'Вовненку Вадиму Сергійовичу',
        nameWho: 'Вовненка Вадима Сергійовича',
        nameAbbrev: 'Вовненко В.С.',
        nameAbbrevWho: 'Вовненко В.С.',
        position: 'Провідний інженер'
    },

]

export default employes