const places = [
    {
        id: '0',
        name: 'ПП Фаворит ІІІ',
        location: 'с.Роздольне',
    },
    {
        id: '1',
        name: 'ПАТ Юрія',
        location: 'м.Черкаси',
    },
    {
        id: '2',
        name: 'ТОВ ЧРП',
        location: 'м.Чорноморск',
    },
    {
        id: '3',
        name: 'ТОВ Компанія Стінгрей',
        location: 'м.Запоріжжя',
    }
]

export default places