import React, { Component } from "react";
import './App.css'

import FormComponent from "./components/form.component.jsx";




class App extends Component {

  render() {

    return (
      <div>
        <FormComponent></FormComponent>
      </div>
    );
  }
};

export default App
